import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { LoginComponent } from "./admin/login/login.component";
import { VendorlocationComponent } from "./admin/vendorlocation/vendorlocation.component";
import { CustomerLocationDetailsComponent } from "./admin/customer-location-details/customer-location-details.component";
import { UniversallinkComponent } from "./admin/universallink/universallink.component";
import { ExpirePageComponent } from "./expire-page/expire-page.component";
import { FeedbackComponent } from "./admin/feedback/feedback.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { ThankyouComponent } from "./admin/thankyou/thankyou.component";

const routes: Routes = [
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  
  { path: "vendor-location-details/:id", component: VendorlocationComponent },
  {
    path: "customer-location-details/:id",
    component: CustomerLocationDetailsComponent,
  },
  { path: "universal-link/:id/:timeStamp", component: UniversallinkComponent },
  { path: "expire-page", component: ExpirePageComponent },
  //{ path: "feedback", component: FeedbackComponent },
  { path: 'feedback/:id', component: FeedbackComponent },
  {path: 'thankyou', component: ThankyouComponent},
  { path: "dashboard", component: DashboardComponent },
  { path: "page-not-found", component: PageNotFoundComponent },
  { path: "access-denied", component: AccessDeniedComponent },
  { path: "", redirectTo: "/admin/login", pathMatch: "full" },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
