import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
})
export class FeedbackComponent implements OnInit, AfterViewInit {
  feedback = {
    task_id: '',
    rating_1: 0,
    rating_2: 0,
    rating_3: 0,
    rating_4: 0,
    rating_5: 0,
    rating_6: 0,
    positiveFeedback: '',
    improvementAreas: '',
    recommendation: '',
  };
  taskId: string = '';
  linkOpenedTime: number | null = null;

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.taskId = params.get('id')!;
      this.feedback.task_id = this.taskId;
      if (this.feedback.task_id) {
        this.trackLinkAndRedirect(this.taskId);
      }
    });
    this.loadKrajeeStarRatingLibraries();
    this.loadGoogleTranslateScript();
  }

  checkSubmissionStatus() {
    const submissionKey = `feedback_submitted_${this.taskId}`;
    const submissionTime = localStorage.getItem(submissionKey);

    if (submissionTime) {
      const submittedAt = parseInt(submissionTime, 10);
      const currentTime = new Date().getTime();

      if (currentTime < submittedAt + 60 * 60 * 1000) {
        Swal.fire('Feedback Already Submitted', 'You have already submitted feedback for this task.', 'info').then(() => {
          this.router.navigate(['/expir-page']);
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.initializeStarRating();
  }

  loadKrajeeStarRatingLibraries() {
    const jqueryScript = this.renderer.createElement('script');
    jqueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    this.renderer.appendChild(document.body, jqueryScript);
    const starRatingCSS = this.renderer.createElement('link');
    starRatingCSS.rel = 'stylesheet';
    starRatingCSS.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-star-rating/4.1.2/css/star-rating.min.css';
    this.renderer.appendChild(document.head, starRatingCSS);
    const starRatingScript = this.renderer.createElement('script');
    starRatingScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-star-rating/4.1.2/js/star-rating.min.js';
    this.renderer.appendChild(document.body, starRatingScript);
    const svgThemeCSS = this.renderer.createElement('link');
    svgThemeCSS.rel = 'stylesheet';
    svgThemeCSS.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-star-rating/4.1.2/themes/krajee-svg/theme.min.css';
    this.renderer.appendChild(document.head, svgThemeCSS);
  }

  loadGoogleTranslateScript() {
    if (document.getElementById('google-translate-script')) return;
    const script = this.renderer.createElement('script');
    script.id = 'google-translate-script';
    script.type = 'text/javascript';
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    this.renderer.appendChild(document.body, script);
    (window as any).googleTranslateElementInit = () => {
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,bn,as,bho,gu,hi,kn,ml,mr,mni-Mtei,or,pa,sa,sd,ta,te,ur',
        },
        'google_translate_element'
      );
    };
  }

  initializeStarRating() {
    const initInterval = setInterval(() => {
      const $ = (window as any).$;
      if ($) {
        $(document).ready(() => {
          $('.rating-loading')
            .rating({
              theme: 'krajee-svg',
              hoverOnClear: false,
              showClear: true,
              size: 'md',
            })
            .on('rating:change', (event: any, value: number) => {
              const targetId = event.target.id;
              this.updateRating(targetId, value);
            });
        });
        clearInterval(initInterval);
      }
    }, 100);
  }

  updateRating(id: string, value: number) {
    const ratingKeyMap: { [key: string]: string } = {
      'rating-1': 'rating_1',
      'rating-2': 'rating_2',
      'rating-3': 'rating_3',
      'rating-4': 'rating_4',
      'rating-5': 'rating_5',
      'rating-6': 'rating_6',
    };
    const key = ratingKeyMap[id];
    if (key) {
      this.feedback[key] = value;
    }
  }

  validateFeedback(): boolean {
    if (!this.feedback.task_id) {
      Swal.fire(
        'Oops!',
        'It seems like there was an issue with your task ID. Please try again or contact support if the issue persists.',
        'error'
      );
      return false;
    }
    const ratings = [
      this.feedback.rating_1,
      this.feedback.rating_2,
      this.feedback.rating_3,
      this.feedback.rating_4,
      this.feedback.rating_5,
      this.feedback.rating_6,
    ];
    if (ratings.some((rating) => rating === 0)) {
      Swal.fire(
        'Kind Reminder!',
        'Your feedback is very important to us. Please take a moment to provide ratings for all the categories.',
        'warning'
      );
      return false;
    }
    if (!this.feedback.positiveFeedback.trim()) {
      Swal.fire(
        'We Value Your Input!',
        'Please share what you liked about our service. Your positive feedback helps us grow and improve.',
        'info'
      );
      return false;
    }
    if (!this.feedback.improvementAreas.trim()) {
      Swal.fire(
        'Help Us Improve!',
        'Let us know the areas we can improve. Your insights are invaluable to us.',
        'info'
      );
      return false;
    }
    if (!this.feedback.recommendation.trim()) {
      Swal.fire(
        'We Appreciate Your Suggestions!',
        'Please share your recommendations. They help us make meaningful changes.',
        'info'
      );
      return false;
    }
    return true;
  }

  handleExpiryLogic() {
    const currentTime = new Date().getTime();
    const expiryTimeKey = 'expiry_time';
    const expiryTime = localStorage.getItem(expiryTimeKey);
    if (expiryTime) {
      const expiryTimeValue = parseInt(expiryTime, 10);
      if (currentTime > expiryTimeValue) {
        this.handleExpiredLink('This link has expired!');
        return;
      }
    } else {
      const defaultExpiry = currentTime + 24 * 60 * 60 * 1000;
      localStorage.setItem(expiryTimeKey, defaultExpiry.toString());
    }
    if (!this.linkOpenedTime) {
      this.linkOpenedTime = currentTime;
      const oneHourAfterOpening = currentTime + 60 * 60 * 1000;
      localStorage.setItem(expiryTimeKey, oneHourAfterOpening.toString());
    }
  }

  handleExpiredLink(message: string) {
    Swal.fire('Error', message, 'error').then(() => {
      this.router.navigate(['/expire-page']);
    });
  }

  trackLinkAndRedirect(taskId: string) {
  let payload= {
      task_id:taskId
    }
    this.commonService.checkFeedbackExpiry(payload).subscribe(
      (response: any) => {
        console.log("response", response);
        if (response.status) {
          //  Swal.fire({ icon: "error", title: response.message });
          //  Swal.fire(response.message);
        }else{
           this.router.navigateByUrl(response.redirect_url);
        }
      },
      (error: any) => {
        console.error("Error in feedback:", error);
      }
    );
  }

  onSubmit() {
    if (!this.validateFeedback()) {
      return;
    }
    this.commonService.feedback(this.feedback).subscribe(
      (response: any) => {
        if (response.status  && response.redirect_url) {
          const submissionKey = `feedback_submitted_${this.taskId}`;
          localStorage.setItem(submissionKey, new Date().getTime().toString());
          Swal.fire('Success', 'Feedback submitted successfully!', 'success').then(() => {
            this.router.navigateByUrl(response.redirect_url);
          });
        } else if (response.redirect_url) {
           this.router.navigateByUrl(response.redirect_url);
        } else {
          Swal.fire('Error', response.message || 'Redirection failed!', 'error');
        }
      },
      (error) => {
        Swal.fire('Error', 'Failed to submit feedback. Please try again later.', 'error');
      }
    );
  }
}