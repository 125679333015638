  // encryptionKey: "83230975909b5d49561be1bb59c5e95f",
  // encryptionIVKey: "fq5W1PgtauSYYTbZ",

  // This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  sessionExpiredSeconds: 43200000,
  googleMapsApiKey: "AIzaSyBf4dk4gKMYMQSm1ukYGK1M5GTZDphfG3I",


  // ---- Begin Tvs Live ----

     production: true,
     baseUrl: 'https://crm-api.tvsservice.com/api/',
     baseUrl_angular: 'https://crm.tvsservice.com/#/',
     baseUrl_video : 'https://crm-api.tvsservice.com/',
     apiurl: 'https://crm-api.tvsservice.com/api/',
     mainJsPath: '/TVS/assets/front/js/main.js',
    CaptchaSiteKey:'6LdiDtIZAAAAAHk13aDi4GWPL-QUBkAihnWKYS2x',
    minPasswordLength: 8,
    maxPasswordLength: 12,
    permissionDeniedMsg: 'You do not have privilege for this page.',
    sessionTimeoutInSeconds: 3600,

    userLogout:'/admin/logout',
    adminLogout:'/admin/logout',

    TodayCase:'/#/admin/today-cases',
    AllCases:'/#/admin/allclaimdetails',
    OpenCases:'/#/admin/opencases',
    CustomerOnboarded:'/#/admin/customeronboarded',
    VendorAssigned:'/#/admin/vendorassigned',
    AssistanceScheduled:'/#/admin/assistancescheduled',
    VendorDeclined:'/#/admin/vendordeclined',
    TaskCompleted:'/#/admin/taskcompleted',
    HoldCases:'/#/admin/hold-cases',

  //---- End Tvs Live ----
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
