<nav class="navbar">
    <a class="navbar-brand" href="#">
        <img src="assets/logo.png" alt="Logo" width="180px" height="auto" />
 
    </a>
</nav>
 
<div class="google-translate-container">
    <div id="google_translate_element"></div>
    <h2 class="feedback-message">Your feedback helps us to improve our <br>
        services.</h2>
 
    <div class="card">
        <h3 class="card-heading">
            How will you rate our Call Centre agent attitude to help in coordination.
        </h3>
        <div class="rating-container">
            <input id="rating-1" name="rating-1" class="kv-ltr-theme-svg-star rating-loading" type="number" value="0"
                min="0" max="5" step="1" dir="ltr" data-size="md" />
        </div>
        <h3 class="card-heading">
            Has our RSA agent reached you within the estimated time.
        </h3>
        <div class="rating-container">
            <input id="rating-2" name="rating-2" class="kv-ltr-theme-svg-star rating-loading" type="number" value="0"
                min="0" max="5" step="1" dir="ltr" data-size="md" />
        </div>
        <h3 class="card-heading">
            How will you rate our RSA agent behaviour.
        </h3>
        <div class="rating-container">
            <input id="rating-3" name="rating-3" class="kv-ltr-theme-svg-star rating-loading" type="number" value="0"
                min="0" max="5" step="1" dir="ltr" data-size="md" />
        </div>
 
        <h3 class="card-heading">
            How would you rate the overall service provided?
        </h3>
        <div class="rating-container">
            <input id="rating-4" name="rating-4" class="kv-ltr-theme-svg-star rating-loading" type="number" value="0"
                min="0" max="5" step="1" dir="ltr" data-size="md" />
        </div>
        <h3 class="card-heading">
            How satisfied were you with the quality of service provided?
        </h3>
        <div class="rating-container">
            <input id="rating-5" name="rating-5" class="kv-ltr-theme-svg-star rating-loading" type="number" value="0"
                min="0" max="5" step="1" dir="ltr" data-size="md" />
        </div>
        <h3 class="card-heading">
            How would you rate the communication throughout the process?
        </h3>
        <div class="rating-container">
            <input id="rating-6" name="rating-6" class="kv-ltr-theme-svg-star rating-loading" type="number" value="0"
                min="0" max="5" step="1" dir="ltr" data-size="md" />
        </div>
        <h3 class="card-heading">What did you like most about our roadside assistance service?</h3>
        <div class="feedback-container">
          <textarea
            id="positive-feedback"
            name="positive-feedback"
            rows="5"
            class="feedback-textarea"
            [(ngModel)]="feedback.positiveFeedback"
          ></textarea>
        </div>
        
        <h3 class="card-heading">What areas do you think we can improve upon?</h3>
        <div class="feedback-container">
          <textarea
            id="improvement-areas"
            name="improvement-areas"
            rows="5"
            class="feedback-textarea"
            [(ngModel)]="feedback.improvementAreas"
          ></textarea>
        </div>
        
        <h3 class="card-heading">Would you recommend our roadside assistance service to others?</h3>
        <div class="feedback-container">
          <textarea
            id="recommendation"
            name="recommendation"
            rows="5"
            class="feedback-textarea"
            [(ngModel)]="feedback.recommendation"
          ></textarea>
        </div>
        
        <div class="send-button">
          <button type="button" class="btn btn-primary btn-block" (click)="onSubmit()">
            Send Feedback
          </button>
        </div>
        
 
        <p class="message">Continue without sending feedback</p>
 
    </div>
</div>