import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: "root",
})
export class EncryptionService {
  key = "environment.encryptionKey";
  Iv = "environment.encryptionIVKey";
  linktime: any;
  character:any;

  constructor() {
    // this.linktime = sessionStorage.getItem("linktime");
    // //console.log("linktime", this.linktime);
    // if(this.linktime){
    // const chunks = this.linktime?.match(/.{1,2}/g);

    // this.character = chunks
    //   .map((chunk) => String?.fromCharCode(parseInt(chunk)))
    //   .join("");

    // //console.log("character", this.character);
    // }
  
  }

  // Function to encrypt the password
  encryptPassword(password: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.key);
    const iv = CryptoJS.enc.Utf8.parse(this.Iv);

    this.linktime = sessionStorage.getItem("linktime");
    //console.log("linktime", this.linktime);
    if(this.linktime){
    const chunks = this.linktime?.match(/.{1,2}/g);

    this.character = chunks
      .map((chunk) => String?.fromCharCode(parseInt(chunk)))
      .join("");

    //console.log("character", this.character);
    }
   
    const encrypted = CryptoJS[this.character]
      .encrypt(password, key, {
        iv: iv,
      })
      .toString();
    const encoutput = btoa(encrypted);
  

    return encoutput;
  }

  // Function to decrypt the password
  decryptPassword(encryptedPassword: any): any {
    const key = CryptoJS.enc.Utf8.parse(this.key);
    const iv = CryptoJS.enc.Utf8.parse(this.Iv);

    this.linktime = sessionStorage.getItem("linktime");
    //console.log("linktime", this.linktime);
    if(this.linktime){
    const chunks = this.linktime?.match(/.{1,2}/g);

    this.character = chunks
      .map((chunk) => String?.fromCharCode(parseInt(chunk)))
      .join("");

    //console.log("character", this.character);
    }
  
    const decrypted = CryptoJS[this.character].decrypt(
      atob(encryptedPassword),
      key,
      {
        iv: iv,
      }
    );
    const decoutput = decrypted.toString(CryptoJS.enc.Utf8);
    return decoutput;
  }

  randonmDecrypt(key: any, iv: any, encryptedData: any): any {
    //  console.log(key, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', iv, ">>>>>>>>>>>>>>>>>>>", encryptedData);

    // Ensure the key and IV are parsed into CryptoJS-compatible format
    key = CryptoJS.enc.Utf8.parse(key);
    iv = CryptoJS.enc.Utf8.parse(iv);

    this.linktime = sessionStorage.getItem("linktime");
    //console.log("linktime", this.linktime);
    if(this.linktime){
    const chunks = this.linktime?.match(/.{1,2}/g);

    this.character = chunks
      .map((chunk) => String?.fromCharCode(parseInt(chunk)))
      .join("");

    //console.log("character", this.character);
    }
   
    const decrypted = CryptoJS[this.character].decrypt(
      atob(encryptedData),
      key,
      {
        iv: iv,
      }
    );
  
    // Convert decrypted data into UTF-8 format
    const decoutput = decrypted.toString(CryptoJS.enc.Utf8);
    return decoutput;
  }


  functiondivideString(inputStr) {
    if (inputStr) {
      const firstPart = inputStr.slice(0, 5);
      const secondPart = inputStr.slice(5, 53);
      const secretkey = secondPart.slice(0, 32);
      const iv = secondPart.slice(32);
      const thirdPart = inputStr.slice(53);
      let concatenatedfirstthird = firstPart + thirdPart;
      let encryptedDetails = this.randonmDecrypt(
        secretkey,
        iv,
        concatenatedfirstthird
      );

      return encryptedDetails;
    }
  }

}
// 	"customer_status": true,
// 	"status": true,
// 	"customer_message": "Customer Address Mapped.",
// 	"result": {
// 		"lat": 19.0971904,
// 		"longi": 72.876032,
// 		"cust_curr_addr": "Chhatrapati Shivaji International Airport Area, 4-2, Navpada, Vile Parle East, Vile Parle, Mumbai, Maharashtra 400099, India",
// 		"city": "Navpada",
// 		"state": "Vile Parle East",
// 		"cust_pincode": "Vile Parle",
// 		"rsa_status_id": 4,
// 		"vendor_reach_status": false,
// 		"customer_mobile_no": "******2251",
// 		"customer_mobile_no_encrypt": "SUtYRVBIeld4Q01BbGVFdm9ERmJjUT09",
// 		"secreteRandomKey": "hCIKbSopyOLnjRlYdUsFXY6oWxxLobAx",
// 		"secreteRandomIv": "6HUTf6CgUw6mlWog",
// 		"encryData": "SUtYRhCIKbSopyOLnjRlYdUsFXY6oWxxLobAx6HUTf6CgUw6mlWogVBIeld4Q01BbGVFdm9ERmJjUT09",
// 		"vehicle_registration_no": null,
// 		"customer_name": "Vignesh",
// 		"create_time": "2024-11-19 15:08:29",
// 		"created_at": "2024-11-20 15:08:29"
// 	}
// }
// hCIKbSopyOLnjRlYdUsFXY6oWxxLobAx; 
// 6HUTf6CgUw6mlWog 
// SUtYRVBIeld4Q01BbGVFdm9ERmJjUT09