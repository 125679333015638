<div class="content container-fluid">
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-12">
        <img src="assets/front/img/tvs.png" width="180px" />
      </div>
    </div>
  </div>
  
  <section id="portfolio">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadowbox">
          <div class="card-body pb-0">
            <div class="table-responsive">
              <div class="row">
                <div class="col-md-5">
                  <div class="cmap-wrap p-0">
                    <div #mapContainer id="map"></div>
                    <strong>
                      <h4 #mapContainer id="msg"></h4>
                    </strong>
                  </div>
                </div>
                <div class="col-md-6">
                  <img
                  class="image-vendor"
                  src="assets/front/img/vendar-icon.png"
                  alt="IMG"
                /><b>Vendor Location</b>
                <img
                class="image-customer"
                  src="assets/front/img/customer.png"
                  alt="IMG"
                />
                <b>Customer Location</b>
                <img
                class="image-workshop"
                  src="assets/front/img/workshop.jpg"
                  alt="IMG"
                  style="margin-left: 10px"
                /><b>Workshop Location</b> <br>
                  <h4><strong>Nearest Workshop Detail:</strong></h4>
                  <ul>
                    <li>
                      <h5>
                        <strong>Workshop Name </strong>:{{workshopInfo?.contact_person || "NA"}}
                      </h5>
                    </li>
                    <li>
                      <h5>
                        <strong>Mobile No</strong>:{{workshopInfo?.mobile || "NA"}}
                      </h5>
                    </li>
                    <li>
                      <h5>
                        <strong>Location</strong>:{{workshopInfo?.location || "NA"}}
                      </h5>
                    </li>
                  </ul>
                  <h4><strong>Customer Details:</strong></h4>
                  <ul>
                    <li>
                      <h5>
                        <strong>Customer Name</strong> :
                        {{customerName || "NA"}}
                      </h5>
                    </li>
                    <li>
                      <h5>
                        <strong>Address</strong> :
                        {{custCurrAddr || "NA"}}
                      </h5>
                    </li>
                    <li>
                        <h5>
                      <strong>Mobile No </strong> :
                      {{ displayMobileNo }}
                      <span (click)="toggleMaskedData(displayMobileNo)" style="cursor: pointer">
                        <i
                          class="fa"
                          [ngClass]="isEyeIconVisible ? 'fa-eye' : 'fa-eye-slash'"
                        ></i>
                      </span>
                    </h5>
                      <!-- <h5>
                        <strong>Mobile No </strong> :
                        {{ toggleMaskedData(customerMobileNo) || "NA" }}
                        <span (click)="toggleMaskedData(customerMobileNo)" style="cursor: pointer">
                          <i
                            class="fa"
                            [ngClass]="
                              isEyeIconVisible ? 'fa-eye-slash' : 'fa-eye'
                            "
                          ></i>
                        </span>
                      </h5> -->
                    </li>
                    <li>
                      <h5>
                        <strong>Vehicle Registration No </strong> :
                        {{registrationNo || "NA"}}
                      </h5>
                    </li>
                  </ul>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row">
        <div
          class="col-md-12 d-flex justify-content-center"
          style="height: 24px"
        >
          <p style="color: whitesmoke !important ; font-size: small !important">
            © 2019. All rights reserved. | Designed by
            <a
              style="color: red !important"
              href="https://www.indicosmic.com/"
              title="Indicosmic Capital Private Limited"
              ><span>Indicosmic Capital Private Limited</span></a
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>

<div
  class="modal modal-overlaybg"
  tabindex="1"
  role="dialog"
  [ngStyle]="{ display: displayLocationModal }"
>
  <!--  (click)="assignCustomerLocation()" -->
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ popupTitle }}</h4>
        <button
          (click)="closePopup()"
          type="button"
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <form [formGroup]="customerLocationForm" >
        <div class="form-group col-lg-12">
          <div class="form-group">
            <div class="form-group">
              <label>Customer Location</label>
              <span style="color: red">*</span>
              <input
              ng-model="name"
              id="customer_addrss"
                formControlName="customer_addrss"
                type="text"
                placeholder="Enter your Address"
                class="form-control searchInput"
              />
              <!-- <div class="address-list" *ngIf="showAddressList">
                <ul>
                  <li
                    *ngFor="let address of addresses"
                    (click)="setSearchAddress(address)"
                  >
                    {{ address }}
                  </li>
                  
                </ul>
              </div> -->
              <div
                class="text-danger"
                *ngIf="
                  customerLocationForm.get('customer_addrss').invalid &&
                  (customerLocationForm.get('customer_addrss').dirty ||
                    customerLocationForm.get('customer_addrss').touched)
                "
              >
                <span
                  *ngIf="customerLocationForm.get('customer_addrss').errors?.required"
                  >Location is required</span
                >
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>City</label>
            <span style="color: red">*</span>
            <input
              formControlName="city"
              placeholder="Enter City"
              type="text"
              maxlength="10"
              class="form-control" 
            />
            <div
              class="text-danger"
              *ngIf="
                customerLocationForm.get('city').invalid &&
                (customerLocationForm.get('city').dirty ||
                  customerLocationForm.get('city').touched)
              "
            >
              <span *ngIf="customerLocationForm.get('city').errors?.required"
                >Mobile number is required</span
              >
            </div>
          </div>
          <br />
          <button
            type="button"
            class="btn btn-custom green"
            (click)="closePopup1()"
            data-dismiss="modal"
          >
            Close</button
          >&nbsp;
          <input
            class="btn btn-custom green"
            type="submit"
            value="Save"
            (click)="saveAddress()"
          />

          <!-- // (click)="saveAddressData()" -->
        </div>
      </form>
    </div>
  </div>
</div>
<app-loader [loaderActive]="loaderActive"></app-loader>
